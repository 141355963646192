var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("FindImgGame", {
        attrs: {
          wheelList: _vm.wheelList,
          wheelImage: _vm.wheelImage,
          chineseList: _vm.chineseList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }