<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="8" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Míngtiān xīngqī jǐ (xià)",
        hanzi: "明天星期几？ (下)",
        english: "What day is it tomorrow? (II)",
        sound: require("@/assets/audio/L1/1-Lesson/lesson-61-title.mp3")
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>