<template>
  <div class="game-container">
    <SpeakingSentence
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showSentence="showSentence"
      @showSecondImg="showSecondImg"
      :isTwoImg="true"
      :lesson="411"
      :isBlueBackground="true"
      :hasSentence="false"
      :hasSmallMenu="true"
      :hasPointer="true"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      originGameList: [],
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-1-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-1-2.svg`),
          // pinyin: "Tā xǐhuan yú.",
          // hanzi: "她喜欢鱼。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-2-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-2-2.svg`),
          // pinyin: "Tā xǐhuan gǒu.",
          // hanzi: "她喜欢狗。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-3-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-3-2.svg`),
          // pinyin: "Tā bù xǐhuan māo.",
          // hanzi: "她不喜欢猫。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-4-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-4-2.svg`),
          // pinyin: "Tā bù xǐhuan cháng tóufa.",
          // hanzi: "她不喜欢长头发。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-5-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-5-2.svg`),
          // pinyin: "Tā bù xǐhuan cháng tóufa.",
          // hanzi: "她不喜欢长头发。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-6-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-6-2.svg`),
          // pinyin: "Tā bù xǐhuan cháng tóufa.",
          // hanzi: "她不喜欢长头发。",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-7-1.svg`),
          secondImg: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-62/image-7-2.svg`),
          // pinyin: "Tā bù xǐhuan cháng tóufa.",
          // hanzi: "她不喜欢长头发。",
          isShowSentence: false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-yellow.svg"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-white.svg"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-red.svg"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-yellow.svg"),
          isCircle: true,
          startNumberRange: 4,
          endNumberRange: 4,
        },
        {
          image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-white.svg"),
          isCircle: true,
          startNumberRange: 5,
          endNumberRange: 5,
        },
        {
          image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-red.svg"),
          isCircle: true,
          startNumberRange: 6,
          endNumberRange: 6,
        },
        {
          image: require("@/assets/img/16-GAMES/G27-click-and-erase/question-yellow.svg"),
          isCircle: true,
          startNumberRange: 7,
          endNumberRange: 7,
        },
      ],
    };
  },
  created() {
    this.originGameList = JSON.parse(JSON.stringify(this.gameList));
  },
  components: {
    SpeakingSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item, index) => {
        item.isShowSentence = false;
        item.bgImg = this.originGameList[index].bgImg;
      });
    },
    showSentence(index) {
      this.gameList[index - 1].isShowSentence = true;
    },
    showSecondImg(index) {
      this.gameList[index - 1].bgImg = this.gameList[index - 1].secondImg;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
