<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="62"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      titleInfo: {
        hanzi: "星期……",
        pinyin: "xīngqī...",
      },
      bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/background.svg"),
      optionList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/calendar-1.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/calendar-1.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/calendar-2.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/calendar-2.svg"),
          id: 2,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/note.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/note.svg"),
          id: 3,
        },
        {
          bgImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/phone.svg"),
          clickImg: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/phone.svg"),
          id: 4,
        },
      ],
      maskImgList: [
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/calendar-1-story.svg"),
          id: 1,
          centencePinyin: "Liù yuè yī hào shì xīngqī jǐ?",
          sentenceHanzi: "6月1号是星期几？",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/calendar-2-story.svg"),
          id: 2,
          centencePinyin: "Jīntiān shì Xīngqīliù.",
          sentenceHanzi: "今天是星期六。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/note-story.svg"),
          id: 3,
          centencePinyin:
            "Xīngqīyī chī miàntiáor. Xīngqīèr chī kǎoyā. Xīngqīsān chī mǐfàn hé píngguǒ.",
          sentenceHanzi: "星期一吃面条儿。星期二吃烤鸭。星期三吃米饭和苹果。",
        },
        {
          img: require("@/assets/img/16-GAMES/G26-click-zoom/lesson-62/phone-story.svg"),
          id: 4,
          centencePinyin: "Xīngqīsì shì tā de shēngrì.",
          sentenceHanzi: "星期四是他的生日。",
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
