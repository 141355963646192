<template>
  <div class="game-container">
    <FindImgGame
      :wheelList="wheelList"
      :wheelImage="wheelImage"
      :chineseList="chineseList"
    />
  </div>
</template>

<script>
import FindImgGame from "@/components/Course/GamePage/FindImgGame";
export default {
  data() {
    return {
      wheelImage: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/spinner.svg"),
      wheelList: [
        {
          id: 6,
          data: 1,
          position: 242.5,
        },

        {
          id: 5,
          data: 5,
          position: 140,
        },
        {
          id: 1,
          data: 2,
          position: 17.5,
        },
      ],
      chineseList: [
        {
          id: 1,
          number: 2,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/1.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/1-done.svg"),
          showContent: false,
        },
        {
          id: 2,
          number: 0,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/2.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/2-done.svg"),
          showContent: false,
        },

        {
          id: 3,
          number: 2,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/3.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/3-done.svg"),
          showContent: false,
        },

        {
          id: 4,
          number: 1,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/4.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/4-done.svg"),
          showContent: false,
        },

        {
          id: 5,
          number: 2,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/5.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/5-done.svg"),
          showContent: false,
        },
        {
          id: 6,
          number: 1,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/6.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/6-done.svg"),
          showContent: false,
        },
        {
          id: 7,
          number: 0,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/7.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/7-done.svg"),
          showContent: false,
        },
        {
          id: 8,
          number: 2,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/8.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/8-done.svg"),
          showContent: false,
        },
        {
          id: 9,
          number: 1,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/9.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/9-done.svg"),
          showContent: false,
        },
        {
          id: 10,
          number: 0,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/10.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/10-done.svg"),
          showContent: false,
        },
        {
          id: 11,
          number: 1,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/11.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/11-done.svg"),
          showContent: false,
        },
        {
          id: 12,
          number: 0,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/12.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/12-done.svg"),
        },
        {
          id: 13,
          number: 0,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/13.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/13-done.svg"),
          showContent: false,
        },
        {
          id: 14,
          number: 1,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/14.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/14-done.svg"),
          showContent: false,
        },
        {
          id: 15,
          number: 2,
          image: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/15.svg"),
          imageYellow: require("@/assets/img/16-GAMES/G38-boardgame/lesson-62/15-done.svg"),
          showContent: false,
        },
      ],
    };
  },
  components: {
    FindImgGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
